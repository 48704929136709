import React, { useEffect, useState } from "react"
import axios from "axios"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Layout from "../components/layout"
import SubBanner from "../components/SubBanner"
import styled from "styled-components"
import tw from "tailwind.macro"
import Link from "../components/Link"
import Breadcrumb from "../components/Breadcrumb"
import ListLoading from "../components/ListLoading"

export const query = graphql`
  query categoryListing($id: String!) {
    category: allWpProductCategory(filter: { id: { eq: $id } }) {
      nodes {
        name
        description
        slug
        acfCategory {
          categoryImage {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
        ...ProductCategorySEO
      }
    }
  }
`

const CategoryItem = styled.div`
  ${tw`w-full mb-48 md:mb-30 md:w-1/2 lg:w-1/3 xl:w-1/4 lg:px-18 xl:mb-48`}

  .product-item-inner {
    ${tw`mx-auto`}
    max-width: 325px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      max-width: 244px;
    }

    @media (min-width: 1024px) {
      max-width: 320px;
    }

    @media (min-width: 1280px) {
      max-width: 325px;
    }

    .category-name {
      flex: 1;
    }
  }

  img {
    height: 303px;
    border-radius: 60px 0;
    ${tw`w-full object-cover`}

    @media (min-width: 768px) {
      height: 244px;
    }

    @media (min-width: 1024px) {
      height: 325px;
    }
  }

  h4 {
    ${tw`font-fb text-xl text-black-dark leading-7 mt-16`}
  }

  p {
    ${tw`leading-9 font-medium mt-7`}
    flex: 1;
  }

  table {
    ${tw`w-full table-fixed mt-26`}

    thead {
      ${tw`text-left`}
    }

    th {
      ${tw`font-fb`}
    }

    td {
      ${tw`font-medium`}
    }
  }

  .btn-wrap {
    ${tw`flex items-end`}
    flex-grow: 1;
  }
`

const CategoryListing = ({ location, pageContext }) => {
  const [result, setResult] = useState(null)

  useEffect(() => {
    const grapqlURL = `${process.env.SOURCE_URL}/graphql`

    const fetchData = async () => {
      const { data: subCategories } = await axios({
        url: grapqlURL,
        method: "post",
        data: {
          query: `
						query {
							subCategories: productCategory(id: "${pageContext.id}") {
								name
								description
								slug
								children(first: 1000) {
									nodes {
										name
										slug
										uri
										id
										acfCategory {
											categoryImage {
                        sourceUrl(size: MEDIUM)
                      }
										}
									}
								}
								products {
									nodes {
										slug
										id
										title
										acfProducts {
											description: productDescription
											excerpt: productSmallDescription
											options: productOption {
												package: productPackage
												size: productSize
												weight: productWeight
												featured: productFeatured
											}
											images: productImages {
												sourceUrl(size: MEDIUM)
											}
										}
									}
								}
							}
						}
					`,
        },
      })
      setResult(subCategories.data)
    }
    fetchData()
  }, [pageContext.id])

  const breadcrumbsData = [
    {
      title: "All Products",
      url: "/products",
      visibleInMobile: true,
    },
    {
      title: `${result?.subCategories?.name}`,
      url: "",
      visibleInMobile: false,
    },
  ]

  const featuredProducts = product => {
    return (
      product?.acfProducts?.options?.filter(option => option.featured).length >
      0
    )
  }

  return (
    <Layout path={location.pathname}>
      <SEO
        data={{
          title: `Category - ${result?.subCategories?.name}`,
          metaDesc: result?.subCategories?.description,
        }}
      />
      <SubBanner mainTitle={result?.subCategories?.name} />
      <Breadcrumb pages={breadcrumbsData} noViewAll={true} />
      <div className="max-w-xl mx-auto px-18 md:px-40 lg:px-60 xxl:px-0 section-gap-sm xl:section-gap-xl">
        <div className="flex flex-wrap md:-mx-30 lg:-mx-18">
          {result ? (
            <>
              {result?.subCategories?.children.nodes.length > 0 &&
                result?.subCategories?.children.nodes.map(cat => (
                  <CategoryItem key={cat.id}>
                    <div className="product-item-inner">
                      <Link
                        to={`/products/${result?.subCategories?.slug}/${cat.slug}`}
                      >
                        <img
                          src={cat.acfCategory?.categoryImage?.sourceUrl}
                          alt={cat.name}
                        />
                      </Link>
                      <Link
                        to={`/products/${result?.subCategories?.slug}/${cat.slug}`}
                        className="category-name"
                      >
                        <h4>{cat.name}</h4>
                      </Link>

                      <Link
                        className="btn-secondary btn-secondary-rounded-sm xl:btn-secondary-rounded-xl btn-sm mt-16 xl:mt-22"
                        to={`/products/${result?.subCategories?.slug}/${cat.slug}`}
                      >
                        View Category
                      </Link>
                    </div>
                  </CategoryItem>
                ))}
              {result?.subCategories?.products.nodes.length > 0 &&
                result?.subCategories?.products.nodes.map(product => (
                  <CategoryItem key={product.id}>
                    <div className="product-item-inner">
                      <img
                        src={product?.acfProducts?.images[0]?.sourceUrl}
                        alt={product?.title}
                      />
                      <h4>{product?.title}</h4>
                      {/* <p
                        dangerouslySetInnerHTML={{
                          __html: product?.acfProducts?.excerpt,
                        }}
                      ></p> */}
                      {featuredProducts(product) && (
                        <table>
                          <thead>
                            <tr>
                              <th>Weight</th>
                              <th>Size</th>
                              <th>Package</th>
                            </tr>
                          </thead>
                          <tbody>
                            {product?.acfProducts?.options.map(
                              option =>
                                option.featured && (
                                  <tr key={option.size}>
                                    <td>{option.weight}</td>
                                    <td>{option.size}</td>
                                    <td>{option.package}</td>
                                  </tr>
                                )
                            )}
                          </tbody>
                        </table>
                      )}
                      <div className="btn-wrap">
                        <Link
                          className="btn-secondary btn-secondary-rounded-sm xl:btn-secondary-rounded-xl btn-sm mt-16 xl:mt-22"
                          to={`/products/${result?.subCategories?.slug}/${product.slug}`}
                        >
                          View Product
                        </Link>
                      </div>
                    </div>
                  </CategoryItem>
                ))}
            </>
          ) : (
            <ListLoading />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default CategoryListing
