import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import tw from "tailwind.macro"

const ListLoadingSection = styled.div`
  ${tw`w-full`}

  svg {
    ${tw`text-primary m-auto`}
  }
`

const ListLoading = () => {
  return (
    <ListLoadingSection>
      <FontAwesomeIcon
        fixedWidth
        size="6x"
        spin
        icon={faSpinner}
        className="flex"
      />
    </ListLoadingSection>
  )
}

export default ListLoading
